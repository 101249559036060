import axios from "axios";
import { toast } from "react-toastify";

// export const API_URL = 'https://onecmadmin.ibt-learning.com/'
export const API_URL = 'https://onecm-apis.onecannabismarketinc.com/' 
// export const API_URL = 'https://stagging-1cm-admin-api.ammag.tech/'
// export const API_URL = 'https://onecm.ammag.tech/'
export const WareHouse_API_URL = 'https://onecm-warehouse.onecannabismarketinc.com/api/middleware/'
export const Node_BE_URL = 'https://onecmapp-apis.onecannabismarketinc.com'
// export const Node_BE_URL = 'https://onecm-loyalty.ammag.tech'
// export const Node_BE_URL = 'http://192.168.1.42:8080'  
export const ScreeNname  = {
  nftCollection :'nft collection management'
}
export const Activity_URL = 'https://onecm-apis.onecannabismarketinc.com'
// export const Activity_URL = 'https://onecm.ammag.tech'

export const Default = 'https://onecmapp-apis.onecannabismarketinc.com/api/nft/font-settings'
// export const Default = 'https://onecm-loyalty.ammag.tech/api/nft/font-settings'
export const Update_Settings = 'https://onecmapp-apis.onecannabismarketinc.com/api/nft/font-settings'
// export const Update_Settings = 'https://onecm-loyalty.ammag.tech/api/nft/font-settings'
export const store_Settings = 'https://onecmapp-apis.onecannabismarketinc.com/api/nft/font-settings/add'
// export const store_Settings = 'https://onecm-loyalty.ammag.tech/api/nft/font-settings/add'
export const ColorPickerModes = {
  STANDARD_COLOR: "titleMap",
  NONE: "",
};
export const NamePickerModes = {
  STANDARD_COLOR: "nameMap",
  NONE: "",
};
// export const PICTURE_BASE_URL = "https://onecm.ammag.tech/";
export const PICTURE_BASE_URL = "https://onecm-apis.onecannabismarketinc.com/";

// export const API_URL = 'https://onecmadmin.ibt-learning.com/'
export const MAX_FILE_SIZE_BYTES =52428800

export const http = {
  setAuthorizationHeader: () => {
    const accessToken = localStorage.getItem("Token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    axios.defaults.headers.Authorization = "Bearer " + accessToken;
  },
  request(config = {}) {
    return axios.request(config);
  },
  get(url, config = {}) {
    return axios.get(url, config);
  },
  post(url, data = {}, config = {}) {
    return axios.post(url, data, config);
  },
  put(url, data = {}, config = {}) {
    return axios.put(url, data, config);
  },
  patch(url, data = {}, config = {}) {
    return axios.patch(url, data, config);
  },
  delete(url, config = {}) {
    return axios.delete(url, config);
  },
  pageSize: 999999,
};


export const colors={
mainColor:'#ad79f7'
}
 export const customStyles = {
  menu: (provided) => ({
    ...provided,
    width: '850px',
  }),
  control: (provided) => ({
    ...provided,
    width: '850px',
  }),
};

 export const previewSizes = {
  Greenline_Mobile: { width: "100%", height: "650px" },
  Greenline_Desktop: { width: "100%", height: "360px" },
  Menu_Board_TV_Landscape: { width: "100%", height: "850px" },
  PORTRAIT_SIZE: { width: "100%", height: "1920px" },
  Mobile_NFT:{width:'400px',height:'400px'},
  A4_Size:{width:'793.7px',height:' 1122.52px'},
  A4Size:{width:'793.7px',height:' 1122.52px'}

};

export const downloadSizes = {
  Greenline_Mobile: { width: "1800px", height: "810px" },
  Greenline_Desktop: { width: "2680px", height: "360px" },
  Menu_Board_TV_Landscape: { width: "1920px", height: "1080px" },
  PORTRAIT_SIZE: { width: "1080px", height: "1920px" },
  Mobile_NFT:{width:'400px',height:'400px'},
  A4_Size:{width:'793.7px',height:' 1122.52px'},
  A4Size:{width:'793.7px',height:' 1122.52px'}


};


export const API_URLs =
  "https://onecmapp-apis.onecannabismarketinc.com/api/getProducts";


export const sizes = [
    { name: 'GreenlineMobile', dimensions: { width: '1800px', height: '810px' } },
    { name: 'GreenlineDesktop', dimensions: { width: '2680px', height: '360px' } },
    { name: 'MenuBoardTVLandscape', dimensions: { width: '1920px', height: '1080px' } },
    { name: 'MenuBoardTVPortrait', dimensions: { width: '1080px', height: '1920px' } },
  ];
  export const placeholderurlmobile='https://placehold.co/1800x810'
  export const placeholderurldesktop='https://placehold.co/2680x360'
  export const placeholderurllandscape='https://placehold.co/1920x1080'
  export const placeholderurlpotrait='https://placehold.co/1080x1920'
  export const placeholderurlMobileNFT='https://placehold.co/400x400'
   export const A4_Size='https://placehold.co/793x1122'


  export const REMOVE_BG = 'https://onecm-banner.ammag.tech/remove-background';

  export const currentFontSizes = [
     'Select Size','8px','10px','12px', '14px', '16px', '18px', '20px', '24px', '28px', '32px', '36px','38px','40px','50px','60px','70px','80px','90px','118px'
     ,'150px','190px','200px'
  ];
  export const fontSizes = [

    'Select Size','8px','10px','12px', '14px', '16px', '18px', '20px', '24px', '28px', '32px', '36px','38px','40px','50px','60px','70px','80px','90px','118px','150px'
    ,'190px','200px'
  ];
  export const FooterSelectorfontSizes = [

    'Select Size','8px','10px','12px', '14px', '16px','17px', '18px', '20px','21px', '24px', '28px', '30px'
  ];
  
  export const fontFamilies = [
   'Lovelo Black',
   'Arial',
   'Verdana',
   'Times New Roman',
   'Helvetica',
   'Tahoma', 
   'Trebuchet MS',
   'Garamond',
   'Arial Black',
   'Century Gothic  ',
   
  ];
  export const INITIAL_name_Size_NFT = "32px";
  export const INITIAL_Font_Size_name = "14px";
  export const INITIAL_TITLE_COLOR = "#000000";
  export const INITIAL_TITLE_FONT_FAMILY = "Lovelo Black";
  export const INITIAL_titleFontSizeMobile = "50px";
  export const INITIAL_nameFontSizeMobile = "32px";
  export const INITIAL_priceFontSizeMobile = "30px";
  export const INITIAL_footerFontSizeMobile = "20px";

  export const INITIAL_TITLE_COLOR_PDF = "#a9d18e";
  export const Star_Color = "#ffffff";

export const TitleColorMAp ="colormap"
export const CustomColorMAp ="standard"
export const NameColorMAp ="ColorMap"
export const CustomNameColorMAp ="Standard"
 

  export const modalStyles = {
    Greenline_Mobile: {
      width: "1800px",
      height: "810px",
      productDetails: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        fontSize: "14px",
      },
    },
    Greenline_Desktop: {
      width: "2680px",
      height: "360px",
      productDetails: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
        gap: "20px",
        fontSize: "18px",
      },
    },
    Menu_Board_TV_Landscape: {
      width: "1920px",
      height: "1080px",
      productDetails: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "15px",
        fontSize: "16px",
      },
    },
    PORTRAIT_SIZE: {
      width: "1080px",
      height: "1920px",
      productDetails: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "12px",
        fontSize: "20px",
      },
    },
  };

  export const SHOW_MODAL = "showModal";
export const HANDLE_CLOSE_MODAL = "handleCloseModal";
export const BACKGROUND = "background";
export const TITLE = "title";
export const TITLE_COLOR = "titleColor";
export const TITLE_FONT_SIZE = "titleFontSize";
export const PRICE_FONT_SIZE = "priceFontSize";
export const TITLE_FONT_SIZE_MOBILE = "titleFontSizeMobile";
export const PRICE_FONT_SIZE_MOBILE = "priceFontSizeMobile";
export const NAME_FONT_SIZE_MOBILE = "nameFontSizeMobile";
export const FOOTER = "footer";
export const PROCESSED_IMAGE = "processedImage";
export const SHOW_FOOTER_INPUT = "showFooterInput";
export const SHOW_TC = "showTC";
export const NAME_FONT_SIZE = "nameFontSize";
export const PRODUCT_DETAILS = "productDetails";
export const TITLE_FONT_FAMILY = "titleFontFamily";
export const CUSTOM_PRICE = "customPrice";
export const NAME_COLOR = "nameColor";
export const PRICE_COLOR = "priceColor";
export const BANNER_TYPE = "currentBannerType";


export const TabKeys = {
  HOME: "home",
  GREENLINE_MOBILE: "GreenLine Mobile",
  GREENLINE_DESKTOP: "Greenline Desktop",
  MENU_BOARD_TV: "Menu Board TV",
  PORTRAIT_SIZE: "Potrait Size",
  NFT_SIZE: "NFT Size",
  DOWNLOAD_BANNERS: "Download Banners",
};

export const TabKey = Object.freeze({
  GREENLINE_MOBILE: "GreenLine Mobile",
  GREENLINE_DESKTOP: "GreenLine Desktop",
  MENU_BOARD_TV_LANDSCAPE: "Menu Board TV Landscape",
  PORTRAIT_SIZE: "Portrait Size",
  NFT_SIZE: "NFT Size",
});

export const CrossIcon=() =>{
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  );
}

export const standardColors = [
  // Red/Pink Shades
  "crimson", "darkred", "deeppink", "firebrick", "hotpink", "indianred", "lightcoral",
  "lightpink", "lightsalmon", "mediumvioletred", "orangered", "palevioletred",
  "pink", "red", "salmon", "darksalmon", "tomato", "orangered",



  // Purple/Violet Shades
  "blueviolet", "darkmagenta", "darkorchid", "darkviolet", "indigo", "lavender",
  "magenta", "mediumorchid", "mediumpurple", "mediumvioletred", "orchid", "plum",
  "purple", "rebeccapurple", "thistle", "violet",

  // Blue Shades
  "aqua", "aquamarine", "blue", "blueviolet", "cadetblue", "cornflowerblue",
  "cyan", "darkblue", "darkcyan", "darkslateblue", "deepskyblue", "dodgerblue",
  "lightblue", "lightcyan", "lightseagreen", "lightskyblue", "lightslategray",
  "mediumaquamarine", "mediumblue", "mediumslateblue", "midnightblue",
  "navy", "powderblue", "royalblue", "skyblue", "slateblue", "slategray", "steelblue",
  "teal", "turquoise",

  // Green Shades
  "chartreuse", "yellowgreen","darkgreen", "darkolivegreen", "darkseagreen", "forestgreen",
  "green", "greenyellow", "lawngreen", "lightgreen", "lightseagreen", "lime",
  "limegreen", "mediumseagreen", "mediumspringgreen", "mediumturquoise", "olivedrab",
  "olive", "palegreen", "seagreen", "springgreen", "yellowgreen",

  // Brown Shades
  "burlywood", "chocolate", "darkkhaki", "firebrick", "maroon", "peru",
  "rosybrown", "saddlebrown", "sienna", "tan",

  // Grey/Black Shades
  "black", "darkgray", "dimgray", "gainsboro", "lightgray", "lightsteelblue",
  "silver", "darkslategray", "slategray", "gray",
    // Yellow/Orange Shades
    "darkgoldenrod", "goldenrod","antiquewhite", "bisque", "blanchedalmond", "cornsilk", "gold",
    "khaki", "lemonchiffon", "lightgoldenrodyellow", "lightyellow",
    "moccasin", "navajowhite",  "peachpuff", "cornsilk",
    "wheat", "yellow", 

  // White Shades
  "aliceblue", "azure", "beige", "floralwhite", "ghostwhite", "gainsboro", "honeydew",
  "ivory", "lavender", "lavenderblush", "linen", "mintcream", "mistyrose", "oldlace",
  "papayawhip", "seashell", "snow", "whitesmoke", "white",
];



export const isValidRecipient = (recipient) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^[0-9]{10}$/; // Assuming phone number is 10 digits
  const walletAddressRegex = /^0x[a-fA-F0-9]{40}$/; // Simple Ethereum address regex

  return emailRegex.test(recipient) || phoneRegex.test(recipient) || walletAddressRegex.test(recipient);
};
 export const ERROR_LOGS_LIMIT = 10;


export const defaultFontSizes = {
 'GreenLine Mobile': '16px',
  'Greenline Desktop': '20px',
  'Menu Board TV': '24px',
  'Potrait Size': '28px',
  'NFT Size': '30px',
};
export const formatFooterText = (text) => {
  if (!text) return '';
  // Split into chunks of 50 characters, respecting words when possible
  return text
    .split(' ')
    .reduce((acc, word) => {
      if (acc.length === 0 || acc[acc.length - 1].length + word.length + 1 > 100) {
        acc.push(word); // Start a new line
      } else {
        acc[acc.length - 1] += ` ${word}`; // Append to current line
      }
      return acc;
    }, [])
    .join('\n'); // Join with newline characters
};

 export const PDFformatFooterText = (text) => {
  if (!text) return '';
  return text.replace(/(.{100})/g, '$1\n'); // Inserts a line break every 50 characters
};

export const API_URLS = {
  GET_USER_BY_ID: "https://onecm-apis.onecannabismarketinc.com/api/Nft/GetMissingUsersAsync",
  SEND_TOKENS: "https://onecm-apis.onecannabismarketinc.com/api/Nft/SendTokens",
};

export const TextEffectTypes = {
  GRADIENT: "gradient",
  SHADOW: "shadow",
  OUTLINE: "outline",
  BOLD: "bold",
  ITALIC: "italic",
  UNDERLINE: "underline",
};

export const DESCRIPTION_CONFIG = {
  MAX_LENGTH: 650,
};
export const placeholder = 'https://via.placeholder.com/50'

export const GetAllLocations = async (
  API_URL,
  page,
  token,
  setLoader,
  setLocations,
  setTotalPages,
  itemsPerPage
) => {
  try {
    setLoader(true);

    const response = await axios.get(`${API_URL}api/Nft/GetAllStores`, {
      params: { CurrentPage: page },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data && response.data.data && response.data.data.response) {
      const { response: locationData, totalCount } = response.data.data;

      // Add `tempUrl` for inline editing
      const updatedLocations = locationData.map((location) => ({
        ...location,
        tempUrl: location.url,
      }));
      setLocations(updatedLocations);
      setTotalPages(Math.ceil(totalCount / itemsPerPage));
    } else {
      setLocations([]);
      setTotalPages(1);
    }
  } catch (err) {
    console.error("Error fetching locations:", err);
  } finally {
    setLoader(false);
  }
};


export const handleSaveUrl = async (
  API_URL,
  token,
  locationId,
  locations,
  setLocations,
  setEditingId
) => {
  try {
    const updatedLocation = locations.find(
      (location) => String(location.locationId) === String(locationId)
    );

    if (!updatedLocation) {
      console.error("Location not found for locationId:", locationId);
      return;
    }

    const { tempUrl } = updatedLocation;

    // Construct the payload
    const payload = {
      locationId: String(locationId),
      url: tempUrl,
    };

    const apiUrl = `https://onecm-apis.onecannabismarketinc.com/api/Nft/UpdateStoreURL?LocationId=${encodeURIComponent(locationId)}&Url=${encodeURIComponent(tempUrl)}`;
    
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      setLocations((prevLocations) =>
        prevLocations.map((location) =>
          String(location.locationId) === String(locationId)
            ? { ...location, url: tempUrl }
            : location
        )
      );
      toast.success("URL updated successfully!", {
        autoClose: 2000,
      });
      setEditingId(null);
    } else {
      toast.error("Failed to update URL. Please try again.");
    }
  } catch (err) {
    console.error(err);
    toast.error("Error updating URL.");
  }
};
export const ActivetabDefault = "Activity History"
 export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
   export  const phoneRegex = /^[0-9]{10}$/;
  export   const walletRegex = /^0x[a-fA-F0-9]{40}$/;


  export const  CompanyLocation = {
    "1154" : "Ontario",
    "3501" : "British Columbia",
    "3854" : "New Brunswick",
    "3643" : "Alberta",
    "3515" : "Saskatchewan",
    "4377" : "Costcanliquor Saskatchewan",
    "4347" : "Cost Cannabis",
    "4381" : "Lloydminster",
    "4369" : "Costcanliquor Lloydminster"
  }

  export const SideBar = {
    SEND_COINS: "/sendcoins",
    UserPoints:"/UserPoints",
    Usertransection:"/Usertransection",
    BannerGeneration:'/BannerGeneration',
    StoreLinks:"/StoreLinks",
    UpdateStore:"/UpdateStore"
  };
