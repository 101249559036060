import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import {
  Breadcrumb,
  Button,
  Form,
  Table,
  Tabs,
} from "react-bootstrap";
import CustomLoader from "../Components/CustomeLoader";
import moment from "moment";
import {
  Node_BE_URL,
} from "../constant";
import PaginationComponent from "../pages/PaginationComponent";
import { fetchLogs } from "../shared/api";

const UpdateStore = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 10;

  const loadLogs = async () => {
    setLoading(true);
    try {
      const { logs, totalLogs } = await fetchLogs(
        Node_BE_URL,
        currentPage,
        itemsPerPage
      );
      setLogs(logs);
      setTotalItems(totalLogs);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadLogs();
  }, [currentPage, itemsPerPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // Make sure this updates the state
  };

  return (
    <>
      <Sidebar />
      <CustomLoader isLoading={loading} />
      <CustomLoader isLoading={loading} />

      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel ">
            <div className="full-div">
              {/* BreadCrumb */}
              <Breadcrumb>
                <Breadcrumb.Item> Admin Management </Breadcrumb.Item>
                <Breadcrumb.Item href="#">Update Store</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="full-div mt-4">
              <div className="flex-div-sm">
                <h1 className="Activityhistory">Update Store</h1>

                <ul className="btn-lister">
                  <li>
                    <div className="search-pnl">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          // handleSearch();
                        }}
                      >
                        <div>
                          <Form.Group controlId="formBasicEmail">
                            <div className="input-group">
                              <Form.Control
                                type="search"
                                placeholder="Enter Address, Email, Phone"
                                className="form-control"
                                // value={searchTerm}
                                // onChange={(e) =>
                                //   setSearchTerm(e.target.value)
                                // }
                              />
                              <div className="input-group-append">
                                <Button
                                  className="reg-btn"
                                  type="button"
                                  // onClick={handleSearch}
                                >
                                  <i className="fa fa-search"></i>
                                </Button>
                              </div>
                            </div>
                          </Form.Group>
                        </div>
                      </Form>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="white-div">
              <div className="table-container">
                <div className="table-container-inner">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Location Name</th>
                        <th>Store Name</th>
                        <th>Company</th>
                        <th>Operation</th>
                        <th>Amount</th>
                        <th>Timestamp</th>
                        <th>User Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {logs?.map((log) => (
                        <tr key={log._id}>
                          <td>{log?.gluserId || "N/A"}</td>
                          <td>{log?.userId || "N/A"}</td>
                          <td>{log?.companyId || "N/A"}</td>
                          <td>{log?.operation || "N/A"}</td>
                          <td>{log?.amount || "N/A"}</td>
                          <td>
                            {log?.createdAt
                              ? moment(log?.createdAt).format(
                                  "YYYY-MM-DD hh:mm A"
                                ) // Use "hh:mm A" for AM/PM
                              : "N/A"}
                          </td>
                          <td>{log?.userName || "N/A"}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
              <div className="full-div text-center pagination-container">
                {totalItems === 0 ? (
                  <p>No logs available.</p>
                ) : (
                  <PaginationComponent
                    totalItems={totalItems}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                    customClassNames={{
                      rpbItemClassName: "custom-item",
                      rpbItemClassNameActive: "custom-item--active",
                      rpbGoItemClassName: "custom-go-item",
                      rpbItemClassNameDisable: "custom-item--disable",
                      rpbProgressClassName: "custom-progress-bar",
                      rpbRootClassName: "custom-root",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default UpdateStore;
