// src/redux/nftSlice.js
import { createSlice } from "@reduxjs/toolkit";

const nftSlice = createSlice({
  name: "nft",
  initialState: {
    noOfCopy: 0,
  },
  reducers: {
    setnoOfCopy: (state, action) => {
      state.noOfCopy = action.payload; // Update the noOfCopy value
    },
  },
});

export const { setnoOfCopy } = nftSlice.actions;
export default nftSlice.reducer;
