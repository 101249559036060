import React, { useEffect, useRef } from "react";

const InvalidRecordsModal = ({ show, onClose, data, lineNumbers }) => {
  const modalRef = useRef();


  if (!show) return null;

  return (
    <div className="error-Modal">
      <div className="error-Modal-content" ref={modalRef}>
        <h2>Invalid Recipients</h2>
        <table>
          <thead>
            <tr>
              <th>Line Number</th>
              <th>User Details</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                <td><p>{lineNumbers[index]}</p></td>
                <td>{item.row}</td>
                <td><p>Account Not Found</p></td>
              </tr>
            ))}
          </tbody>
        </table>
        <button  className="reg-btn w-100 big" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default InvalidRecordsModal;
