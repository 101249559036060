// src/redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk"; // Import redux-thunk
import rightSlice from "./rightSlice";
import accountSlice from "./accountSlice";
import tabSlice from "./tabSlice";
import colorReducer from "./colorReducer";
import nftReducer from "./nftSlice";
const store = configureStore({
  reducer: {
    rights: rightSlice,
    account: accountSlice,
    tab: tabSlice,
    color: colorReducer,
    nft: nftReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk), // Add redux-thunk middleware
});

export default store;
