import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import {
  Alert,
  Breadcrumb,
  Button,
  Form,
  Modal,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import CustomLoader from "../Components/CustomeLoader";
import { handleActivityTracking } from "../shared/api";
import moment from "moment";
import { ActivetabDefault, API_URLS, isValidRecipient } from "../constant";
import axios from "axios";
import { toast } from "react-toastify";
import PaginationComponent from "../pages/PaginationComponent";
import InvalidRecordsModal from "./InvalidRecordsModal";

const SendCoins = () => {
  const [loader, setloader] = useState(true);
  const [errorLogs, setErrorLogs] = useState([]);
  const [totalItemspage, setTotalItemspage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTabLive, setActiveTabLive] = useState(ActivetabDefault);
  const [searchTerm, setSearchTerm] = useState(""); // Single input for any search term
  const [recipient, setRecipient] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [noUserFoundMessage, setNoUserFoundMessage] = useState("");
  const [file, setFile] = useState(null);
  const [showInvalidModal, setShowInvalidModal] = useState(false);
  const [invalidData, setInvalidData] = useState([]);
  const [invalidLineNumbers, setInvalidLineNumbers] = useState([]);
  const [error, setError] = useState("");
  const [errorArrayLogs, setErrorArrayLogs] = useState([]);
  const [fileName, setFileName] = useState(""); // State to store file name
  const fileRef = useRef();

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    if (file) {
      setFileName(file.name);
      handleCloseModal();
    }
  };

  const fileInputRef = useRef(null);
  // Function to handle changes in the email input field
  const handleRecipientChange = (e) => {
    const recipient = e.target.value;
    setRecipient(recipient);

    // Only show an error if the input is not empty and it's invalid
    if (recipient && !isValidRecipient(recipient)) {
      setErrorMessage("Please enter a valid email, phone, or wallet address.");
    } else {
      setErrorMessage("");
    }
  };

  const handleSearch = async () => {
    setloader(true);
    setNoUserFoundMessage("");
    setErrorLogs([]);
    try {
      const response = await handleActivityTracking({
        currentPage,
        pageSize: 10,
        isTireBase: false,
        isMarketplace: false,
        isProductBase: false,
        Search: searchTerm.trim(),
      });

      if (response.isSuccess) {
        const items = response.data || [];
        setTotalItemspage(response.totalItems || 0);
        setErrorLogs(items);

        if (items.length === 0) {
          setNoUserFoundMessage("No User Found.");
        }
      } else {
        setNoUserFoundMessage(response.message || "No User Found.");
        setErrorLogs([{ description: response.message || "Unknown error" }]);
        setTotalItemspage(0);
      }
    } catch (error) {
      // Set the error message for unhandled errors
      setNoUserFoundMessage("No User Found.");
      setErrorLogs([{ description: "No User Found." }]);
      setTotalItemspage(0);
    } finally {
      setloader(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch();
    }
  };

  useEffect(() => {
    handleSearch();
  }, [currentPage]);

  const handleChange = (e) => {
    const value = e.target.value;
    setRecipient(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{10}$/;
    const walletRegex = /^0x[a-fA-F0-9]{40}$/;

    if (
      emailRegex.test(value) ||
      phoneRegex.test(value) ||
      walletRegex.test(value)
    ) {
      setError(""); // Valid input
    } else {
      setError(
        "Invalid input. Enter a valid email, phone number, or wallet address."
      );
    }
  };

  const handleSend = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage("");
    setErrorArrayLogs([]);

    // Check conflicting input conditions
    if (recipient && file) {
      setLoading(false);
      setErrorMessage(
        "Please provide either a recipient or upload a CSV file, not both."
      );
      return;
    }

    if (!recipient && !file) {
      setLoading(false);
      setErrorMessage("Please provide a recipient or upload a CSV file.");
      return;
    }
    if(!amount){
      setLoading(false);
      toast.error("Please enter an amount.");
      return;
    }

    try {
      const token = localStorage.getItem("Token");
      if (!token) {
        setLoading(false);
        setErrorMessage("Authorization token not found. Please log in.");
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      let recipientArray = [];

      if (recipient) {
        recipientArray = [recipient.trim()];
      } else if (file) {
        const fileContent = await file.text();
        const rows = fileContent
          .split("\n")
          .map((row) => row.trim())
          .filter(Boolean);

        const Check_payload = rows.map((row, index) => ({
          email: row,
          sequence: index + 1,
        }));

        try {
          const validateResponse = await axios.post(`${API_URLS.GET_USER_BY_ID}`,Check_payload,config);

          if (validateResponse.status === 200) {
            const validatedData = validateResponse.data;

            if (validatedData.data.length === 0) {
              recipientArray = rows;
            } else {

              const updatedErrorArrayLogs = [];
              validatedData.data.forEach((result) => {
                if (result.valid) {

                  recipientArray.push(result.email);
                } else {

                  updatedErrorArrayLogs.push({
                    lineNumber: result.sequence || "N/A",
                    details: result.email || "N/A",
                    status:
                      result.error ||
                      "Invalid email, phone number, or wallet address",
                  });

                  toast.error(
                    `Invalid user at line ${result.sequence || "N/A"}: ${
                      result.email || "N/A"
                    }`,
                    {
                      position: toast.POSITION.TOP_RIGHT,
                      autoClose: 1000,
                    }
                  );
                }
              });
              setErrorArrayLogs(updatedErrorArrayLogs);
            }
          } else {

            setErrorMessage("Validation failed. Please check the input.");
            setLoading(false);
            return;
          }
        } catch (error) {
          setErrorMessage("An error occurred. Please try again.");
          setLoading(false);
        }
      }

      if (recipientArray.length === 0) {

        setLoading(false);
        return;
      }

      const payload = {
        recipient: recipientArray,
        tokenAmount: parseFloat(amount),
      };

      toast.info("Process is continuing in the background...");
      setLoading(false);
      axios.post(`${API_URLS.SEND_TOKENS}`, payload, config)
      .then((sendResponse) => {
        if (sendResponse.status === 200) {
          const { message, invalidUsers } = sendResponse.data.data;
          
          if (message === "Tokens sent successfully.") {
            toast.success("Tokens sent successfully.");
          } else if (message === "Some tokens could not be sent due to errors. Check invalid users.") {
            if (invalidUsers && invalidUsers.length > 0) {
              toast.error(`User(s) not found: ${invalidUsers.join(', ')}`);
            } else {
              toast.success("Coins sent successfully!");
            }
          }
          
          handleSearch();
          setRecipient("");
          setAmount("");
          setFile(null);
          if (fileInputRef.current) fileInputRef.current.value = "";
        }
      })
      .catch((error) => {
        // Handle error if the request fails
        toast.error("An error occurred while sending tokens.");
      });
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
      setLoading(false);
    }

  };

  const handleTabSelect = (key) => {
    setActiveTabLive(key);
  };
  return (
    <>
      <Sidebar />
      <CustomLoader isLoading={loader} />
      <CustomLoader isLoading={loading} />

      <InvalidRecordsModal
        show={showInvalidModal}
        onClose={() => setShowInvalidModal(false)}
        data={invalidData}
        lineNumbers={invalidLineNumbers} // Pass line numbers as well
      />
      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel ">
            <div className="full-div">
              {/* BreadCrumb */}
              <Breadcrumb>
                <Breadcrumb.Item> Admin Management </Breadcrumb.Item>
                <Breadcrumb.Item href="#">Send Coins</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="full-div">
              <div className="flex-div-sm">
                <h1>
                  Coin Transfer <span className="heading"></span>
                </h1>

                <ul className="btn-lister">
                  <li></li>
                </ul>
              </div>
            </div>

            <Form style={{ maxWidth: "900px" }}>
              <Form.Group className="mb-3" controlId="formRecipient">
                <Form.Label>
                  Recipient Email, Phone, or Wallet Address:
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter recipient (Email, Phone, or Wallet Address)"
                  value={recipient}
                  onChange={handleChange}
                  isInvalid={!!error}
                />
                {error && <Alert variant="danger">{error}</Alert>}
              </Form.Group>
              <Form.Label>Upload CSV File:</Form.Label>
              <Form.Group className="mb-3" controlId="formFile">
                <Form.Control
                  ref={fileInputRef}
                  type="file"
                  accept=".csv"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicAmount">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!isNaN(value) && value >= 0) {
                      setAmount(value);
                      setErrorMessage("");
                    } else if (value < 0) {
                      setErrorMessage("Amount cannot be negative.");
                    }
                  }}
                  required
                  type="number"
                  value={amount}
                  placeholder="Enter amount"
                />
                {/* {errorMessage && (
                  <p className="errormessage red mt-2">{errorMessage}</p>
                )} */}
              </Form.Group>

              <div className="spacer-40"></div>

              <ul className="half-list">
                <li>
                  {loader ? (
                    <Button className="reg-btn w-100 big">
                      <span
                        className="spinner-grow spinner-grow-sm"
                        id="loading-span"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Loading...</span>
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      className="reg-btn w-100 big"
                      onClick={handleSend}
                    >
                      Send
                    </Button>
                  )}
                </li>
              </ul>
            </Form>
            <Tabs
              id="controlled-tab-example"
              activeKey={activeTabLive}
              onSelect={(k) => handleTabSelect(k)}
              className="mb-3 custom-tabs-center"
            >
              <Tab eventKey="Activity History" title="Activity History">
                <div className="full-div mt-4">
                  <div className="flex-div-sm">
                    <h1 className="Activityhistory">Activity History </h1>

                    <ul className="btn-lister">
                      <li>
                        <div className="search-pnl">
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              handleSearch();
                            }}
                          >
                            <div>
                              <Form.Group controlId="formBasicEmail">
                                <div className="input-group">
                                  <Form.Control
                                    type="search"
                                    placeholder="Enter Address, Email, Phone"
                                    className="form-control"
                                    value={searchTerm}
                                    onChange={(e) =>
                                      setSearchTerm(e.target.value)
                                    }
                                  />
                                  <div className="input-group-append">
                                    <Button
                                      className="reg-btn"
                                      type="button"
                                      onClick={handleSearch}
                                    >
                                      <i className="fa fa-search"></i>
                                    </Button>
                                  </div>
                                </div>
                              </Form.Group>

                              {noUserFoundMessage && (
                                <p>{noUserFoundMessage}</p>
                              )}
                              {errorLogs.length > 0 && (
                                <ul>
                                  {errorLogs.map((log, index) => (
                                    <li key={index}>{log.name}</li> // Adjust based on your data structure
                                  ))}
                                </ul>
                              )}
                            </div>
                          </Form>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="white-div">
                  <div className="table-container">
                    <div className="table-container-inner">
                      <Table>
                        <thead>
                          <tr>
                            <th>To</th>
                            <th>Amount</th>
                            <th>Timestamp</th>
                            <th>From</th>
                          </tr>
                        </thead>
                        {!loader ? (
                          errorLogs.length > 0 ? (
                            <tbody>
                              {errorLogs.map((log, index) => (
                                <tr key={index}>
                                  <td>{log.description || "N/A"}</td>
                                  <td>{log.amount || "N/A"}</td>
                                  <td>
                                    {log.createdAt
                                      ? moment(log.createdAt).format(
                                          "YYYY-MM-DD HH:mm"
                                        )
                                      : "N/A"}
                                  </td>
                                  <td>{log.senderEmail || "N/A"}</td>
                                </tr>
                              ))}
                            </tbody>
                          ) : (
                            <tbody className="norecords">
                              <tr>
                                <td colSpan="4">
                                  {noUserFoundMessage || "No Record Found"}
                                </td>
                              </tr>
                            </tbody>
                          )
                        ) : (
                          <CustomLoader isLoading={loader} />
                        )}
                      </Table>
                    </div>
                  </div>
                  {!noUserFoundMessage && (
                    <div className="full-div text-center pagination-container">
                      <PaginationComponent
                        totalItems={totalItemspage}
                        itemsPerPage={10}
                        currentPage={currentPage}
                        onPageChange={(pageNumber) => {
                          setCurrentPage(pageNumber);
                          handleSearch();
                        }}
                        customClassNames={{
                          rpbItemClassName: "custom-item",
                          rpbItemClassNameActive: "custom-item--active",
                          rpbGoItemClassName: "custom-go-item",
                          rpbItemClassNameDisable: "custom-item--disable",
                          rpbProgressClassName: "custom-progress-bar",
                          rpbRootClassName: "custom-root",
                        }}
                      />
                    </div>
                  )}
                </div>
              </Tab>
              <Tab eventKey="Error Logs" title="Error Logs">
                <div className="table-container-inner">
                  <Table>
                    <thead>
                      <tr>
                        <th>Line Number</th>
                        <th>User Details</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    {loader ? (
                      <CustomLoader isLoading={loader} />
                    ) : errorArrayLogs.length > 0 ? (
                      <tbody>
                        {errorArrayLogs.map((log, index) => (
                          <tr key={index}>
                            <td>{log.lineNumber}</td>
                            <td>{log.details}</td>
                            <td>{log.status}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody className="norecords">
                        <tr>
                          <td colSpan="3">No Record Found</td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </main>
    </>
  );
};

export default SendCoins;
