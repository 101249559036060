import React from "react";
import { Pagination } from "react-pagination-bar";

const PaginationComponent = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
  customClassNames = {},
  
}) => {
  
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <Pagination
      totalItems={totalItems}
      itemsPerPage={itemsPerPage}
      currentPage={currentPage}
      onPageChange={onPageChange}
      customClassNames={customClassNames}
    />
  );
};

export default PaginationComponent;
