
import axios from 'axios';
import axiosApi from '../axiosApi';

import { Activity_URL, API_URL, Node_BE_URL, PICTURE_BASE_URL } from "../constant";

export const handleGetRole = async () => {
  //This APi used to get all role
  return new Promise((resolve, reject) => {
    axiosApi
      .get(API_URL + `api/SuperAdmin/getroles`)
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};

export const getAllAppUser = async () => {
  //This APi used to get all role
  return new Promise((resolve, reject) => {
    axiosApi
      .get(API_URL + `api/SuperAdmin/GetAllAppUser`)
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};



export const handleGetCollectionCategory = async () => {
  //Get All Collection Category
  return new Promise((resolve, reject) => {
    axiosApi
      .get(API_URL + `api/Nft/GetCategoriesList`)
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};
export const distributeNft = async (id, copies) => {
  //Get All Collection Category
  return new Promise((resolve, reject) => {
    axiosApi
      .post(`${Node_BE_URL}/api/nft/distributeTierNft`, {
        "nftId": id,
        "nftCopy": copies
      })
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};


export const handleGetAllCollection = async (currentPage, searched, checked) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .get(
        API_URL +
        `api/Nft/GetAllCollections?PageSize=12&CurrentPage=${currentPage}&Search=${searched}&approveStatus=${checked}`,
    
      )
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};


export const handleGetCollectionbyId = async (id) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .get(API_URL + `api/Nft/GetNftCollectionById?CollectionId=${id}`)
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};
export const handleGetCollectionbyApproved = async (approved) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .get(API_URL + `api/Nft/GetAllCollections?approveStatus=${approved}`)
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};


export const handleGetUserList = async (currentPage, userType, searched) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .get(
        API_URL +
        `api/SuperAdmin/GetUserList?PageSize=10&CurrentPage=${currentPage}&Search=${searched}&IsPartner=${userType}`,
    
      )
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};
export const handleGetRedeemActivity = async (page ,startDate, endDate,locationId) => {
  return new Promise((resolve, reject) => {
    const limit =10
    axiosApi
      .get(Node_BE_URL + '/api/nft/redeemed-actions', {
        params: {
          page,
          startDate, 
          endDate,
          limit,
          locationId,
        },
      })
      .then((response) => {
        resolve(response); 
      })
      .catch((error) => {
        reject(error); 
      });
  });
};
// Fetches user signup counts from the server's '/api/user-counts' endpoint.
// Resolves with response data on success, rejects with error on failure.

export const handleGetSignupTracking = async () => {
  return new Promise((resolve, reject) => {
    axiosApi
      .get(Node_BE_URL + '/api/user-counts', {
       
      })
      .then((response) => {
        resolve(response); 
      })
      .catch((error) => {
        reject(error); 
      });
  });
};

export const handleGetErrorTracking = async (currentPage, pageSize,searchTerm) => {
  return axiosApi.get(`${Node_BE_URL}/api/error-logs?page=${currentPage}&limit=${pageSize}&search=${searchTerm}`);
};


export const handleActivityTracking = async ({
  pageSize = 1,
  currentPage = 1,
  isTireBase = false,
  isMarketplace = false,
  isProductBase = false,
  Search = "",

}) => {
  try {
    const response = await axiosApi.get(`${Activity_URL}/api/Nft/GetTokenActivityHistory`, {
      params: {
        PageSize: pageSize,
        CurrentPage: currentPage,
        IsTireBase: isTireBase,
        IsMarketplace: isMarketplace,
        IsProductBase: isProductBase,
        Search: Search, // Add Search here
      },
    });

    return response.data;
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};






export const handleGetDiscounts = async (currentPage, startDate, endDate) => {
  return new Promise((resolve, reject) => {
    const limit =10
    axiosApi
      .get(Node_BE_URL + '/api/discounts', {
        params: {
          currentPage,
          startDate, 
          endDate,
          limit,
        },
      })
      .then((response) => {
        resolve(response); 
      })
      .catch((error) => {
        reject(error); 
      });
  });
};


export const handleGetNftByCollectionId = async (id, currentPage, searched, NFTFilters, IsTireBase, IsProduct, IsMarket, isPromotional,expired, LocationId) => {
  
  const pageSize = 12;
  return new Promise((resolve, reject) => {
    let endpoint;
    endpoint = `api/Nft/GetAllNftByCollectionId?collectionId=${id}&PageSize=${pageSize}&CurrentPage=${currentPage}&Search=${searched}&IsTireBase=${IsTireBase}&IsProductBase=${IsProduct}&IsMarketplace=${IsMarket}&IsPromotion=${isPromotional}&nftExpireStatus=${expired}`;

    if (NFTFilters?.length > 0) {
      endpoint += `&filterStatus=${NFTFilters}`;
    }

    if (LocationId !== null && LocationId !== undefined) {
      endpoint += `&LocationId=${LocationId.id}`;
    }
    axiosApi
      .get(API_URL + endpoint)
      .then((response) => {

        resolve(response); 
      })
      .catch((error) => {
        reject(error);  
      });
  });
};


export const handleGetNftById = async (id) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .get(API_URL + `api/Nft/GetMyNftById?nftId=${id}`)
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};
export const handleGetHistory = async (currentPage, id) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .get(
        API_URL +
        `api/Nft/GetNftActivityHistory?PageSize=100&CurrentPage=${currentPage}&nftId=${id}`,
    
      )
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};
export const handleBlockedUser = async (id, activation) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .get(
        API_URL +
        `api/SuperAdmin/UserActivation?adminUserId=${id}&activation=${activation}`,
    
      )
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};
export const handleGetUserById = async (id) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .get(API_URL + `api/SuperAdmin/GetUserById?id=${id}`)
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};
export const handleGetNFTBidHistory = async (id, currentPage) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .get(API_URL + `api/Nft/GetMyAllBidByNftId?NftId=${id}&PageSize=10&CurrentPage=${currentPage}`)
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};
export const handleDeleteUser = async (id) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .delete(API_URL + `api/SuperAdmin/DeleteUser?UserId=${id}`)
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};
export const handleDeleteCollection = async (id) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .delete(API_URL + `api/Nft/DeleteCollection?collectionId=${id}`)
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};
export const handleDeleteNFT = async (id) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .delete(API_URL + `api/Nft/DeleteNFT?NftId=${id}`)
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};

// Function to send an NFT to a user by their email address

export const sendNftToUserByEmail = async (payload) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("Token"); // Retrieve token from local storage

    if (!token) {
      return reject("Authentication token is missing. Please log in again.");
    }

    axios.post(
      `${PICTURE_BASE_URL}api/Nft/SendNFT`,
      {
        recipient: payload.recipient, 
        nftid: payload.nftId,        
        employeeName: "Sting",
        employeeId: "Sting",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error.response?.data?.message || "Failed to send NFT. Please try again.");
    });
  });
};


export const handleDeleteCoupon = async (id) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .delete(API_URL + `api/Coupon/DeleteCoupon?CouponId=${id}`)
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};


/**
 * Function to fetch all coupons from the server.
 * 
 * @param {number} currentPage - The current page number for pagination.
 * @param {string} searched - Search keyword for filtering coupons.
 * @param {boolean} IsTireBase - Filter for tire-based coupons.
 * @param {boolean} IsProduct - Filter for product-based coupons.
 * @param {boolean} IsMarket - Filter for marketplace-based coupons.
 * @param {boolean} IsPromotional - Filter for promotional coupons.
 * @param {object} LocationId - The location ID for filtering coupons (optional).
 * @returns {Promise} A promise that resolves with the response or rejects with an error.
 */
export const handleGetAllCoupons = async (currentPage, searched, IsTireBase, IsProduct, IsMarket, IsPromotional, LocationId) => {
  const pageSize = 10; // Set the page size for pagination

  return new Promise((resolve, reject) => {
    let endpoint = `api/Coupon/GetAllCoupon?PageSize=${pageSize}&CurrentPage=${currentPage}&Search=${searched}&IsTireBase=${IsTireBase}&IsProductBase=${IsProduct}&IsMarketplace=${IsMarket}&IsPromotion=${IsPromotional}`;

    // Add LocationId if provided
    if (LocationId !== null && LocationId !== undefined) {
      endpoint += `&LocationId=${LocationId.id}`;
    }

    // Make the API call using axios
    axiosApi.get(endpoint)
      .then((response) => {
        resolve(response.data);  // Resolve the promise with the API response
      })
      .catch((error) => {
        reject(error);  // Reject the promise with the error
      });
  });
};
export const handleGetCouponNFT = async () => {
  return new Promise((resolve, reject) => {
    axiosApi
      .get(
        API_URL +
        `api/Coupon/GetAllNft`,
    
      )
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};
export const handleGetCouponbyId = async (id) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .get(
        API_URL +
        `api/Coupon/GetCouponById?Id=${id}`,
    
      )
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};
export const getExpiredPromoNftsByCollectionId = (id, currentPage = 1, pageSize = 12) => {
  return axiosApi.get(`${Node_BE_URL}/api/nft/getExpiredPromoNftsByCollectionId/${id}`, {
    params: {
      currentPage,
      pageSize,
    },
  });
};

export const fetchLogs = async (
  Node_BE_URL,
  currentPage,
  itemsPerPage,
  startDate,
  endDate,
  searchTerm
) => {
  try {
    // Create query parameters dynamically
    const queryParams = new URLSearchParams({
      page: currentPage,
      limit: itemsPerPage,
    });

    // Add startDate and endDate if available
    if (startDate) queryParams.append('startDate', startDate);
    if (endDate) queryParams.append('endDate', endDate);

    // Add searchTerm if provided
    if (searchTerm) queryParams.append('search', searchTerm);

    const response = await axios.get(`${Node_BE_URL}/userpointlogs?${queryParams.toString()}`);

    return {
      logs: response.data.logs || [],
      totalLogs: response.data.pagination.totalLogs || 0,
    };
  } catch (error) {
    console.error("Error fetching logs:", error);
    throw error; // You can handle errors in your component
  }
};


export const UserToUserTranserctions = async (
  Node_BE_URL,
  currentPage,
  itemsPerPage,
  startDate,
  endDate,
  searchTerm
) => {
  try {
    // Create query parameters dynamically
    const queryParams = new URLSearchParams({
      page: currentPage,
      limit: itemsPerPage,
    });

    // Add startDate and endDate if available
    if (startDate) queryParams.append('startDate', startDate);
    if (endDate) queryParams.append('endDate', endDate);

    // Add searchTerm if provided
    if (searchTerm) queryParams.append('search', searchTerm);

    // Perform the API request with query parameters
    const response = await axios.get(
      `${Node_BE_URL}/api/nft/getUserActions?${queryParams.toString()}`
    );

    return {
      logs: response.data.actions || [],
      totalLogs: response.data.pagination.totalActions || 0,
    };
  } catch (error) {
    console.error("Error fetching user transactions:", error);
    throw error; // Handle the error in the calling component
  }
};
