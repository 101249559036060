import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import { Breadcrumb, Table, Button } from "react-bootstrap";
import axios from "axios";
import CustomLoader from "../Components/CustomeLoader";
import { API_URL, GetAllLocations, handleSaveUrl } from "../constant";
// import EditIcon from "../SVG/EditIcon";
import { toast } from "react-toastify";
import EditIcon from "../assets/images/edit.png";
const StoreLinks = () => {
  const [locations, setLocations] = useState([]);
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [editingId, setEditingId] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const itemsPerPage = 10;
  const token = localStorage.getItem("Token");
  const inputRef = useRef(null); 

  // Fetch all locations
  const fetchLocations = (page) => {
    GetAllLocations(API_URL, page, token, setLoader, setLocations, setTotalPages, itemsPerPage);
  };

  useEffect(() => {
    fetchLocations(currentPage);
  }, [currentPage]);

  // Handle Input Change
  const handleInputChange = (id, value) => {
    setLocations((prevLocations) =>
      prevLocations.map((location) =>
        location.id === id ? { ...location, tempUrl: value } : location
      )
    );
  };

  useEffect(() => {
    const locationIds = locations.map((location) => location.locationId);
  }, [locations]);

  // Handle Save
  const onSaveUrl = (locationId) => {
    setLoader(true)
    handleSaveUrl(API_URL, token, locationId, locations, setLocations, setEditingId);
    setLoader(false)

  };
  const handleEditClick = () => {
    setIsEditable(true); // Make the input editable
    if (inputRef.current) {
      inputRef.current.focus(); // Focus the input field after making it editable
    }
  };

  return (
    <>
      <Sidebar />
      <CustomLoader isLoading={loader} />
      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel">
            <div className="full-div">
              <Breadcrumb>
                <Breadcrumb.Item>Admin Management</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Store Links</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="table-container">
              <div className="table-container-inner">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      {/* <th>Location Name</th> */}
                      <th>Store Name</th>
                      {/* <th>Company</th> */}
                      <th>Province</th>
                      <th>URL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loader && locations.length > 0
                      ? locations.map((location) => (
                          <tr key={location.id}>
                            {/* <td>{location.locationName}</td> */}
                            <td>{location.company} - {location.locationName}</td>
                            {/* <td>{location.company}</td> */}
                            <td>{location.province}</td>
                            <td>
                              {location.id === editingId ? (
                                <div className="url-cont">
                                  <a
                                    href={location.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {location.url || "No URL"}
                                  </a>
                                </div>
                              ) : (
                                <div>
                                  <input
                                    type="text"
                                    className="Input-URl"
                                    placeholder="Enter URL here"
                                    value={location.tempUrl || ""}
                                    onChange={(e) =>
                                      handleInputChange(
                                        location.id,
                                        e.target.value
                                      )
                                    }
                                    disabled={!isEditable}
                                  />
                                  <img
                                    src={EditIcon}
                                    alt="EditIcon"
                                    className="Editicon"
                                    onClick={handleEditClick}
                                  />
                                  {/* Show Send button only when there is a change in tempUrl */}
                                  {location.tempUrl &&
                                    location.tempUrl !== location.url && (
                                      <Button
                                        type="submit"
                                        className="reg-btn w-1"
                                        style={{ marginLeft: "10px" }}
                                        onClick={() =>
                                          onSaveUrl(location.locationId)
                                        }
                                      >
                                        Update
                                      </Button>
                                    )}
                                </div>
                              )}
                            </td>
                          </tr>
                        ))
                      : !loader && (
                          <tr>
                            <td colSpan="4" className="text-center">
                              No Record Found
                            </td>
                          </tr>
                        )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default StoreLinks;
